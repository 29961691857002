<template>
  <section :class="classes">
    <slot />
  </section>
</template>

<script setup>
const props = defineProps({
  theme: {
    type: String,
    default: 'light' // or 'dark'
  },
  margins: {
    type: String,
    default: 'default' // 'reset-top', 'large'
  }
})

const classes = computed(() => {
  let classString = 'max-w-[100%] overflow-x-hidden '
  if (props.theme === 'faun') {
    classString += ' bg-faun'
  } else if (props.theme === 'dark') {
    classString += ' bg-gray-900'
  } else {
    classString += ' bg-white'
  }
  if (props.margins === 'default' && props.theme === 'dark') {
    classString += ' py-12 md:py-16'
  } else if (props.margins === 'default') {
    classString += ' py-12 md:py-16'
  } else if (props.margins === 'reset-top') {
    classString += ' pb-16 md:pb-24 lg:pb-32 pt-0'
  } else if (props.margins === 'large') {
    classString += ' py-42'
  }
  return classString
})

</script>